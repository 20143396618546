import 'mmenu-js';

export default class Nav {
  constructor() {
    document.addEventListener(
      "DOMContentLoaded", () => {
        new Mmenu("#pochi-menu", {
          scrollBugFix: {
            fix: true
          },
          "extensions": [
            "border-offset",
            "pagedim-black",
            "theme-dark",
            "multiline"
          ]
        });
        function sticky_relocate_2() {
          var window_top = $(window).scrollTop();
          var div_top = $('main').offset().top - 100;
          if (window_top > div_top) {
            $('.pochi-brand').addClass('pinned')
          }
          if(window_top > $('main').position().top){
            $('.pochi-brand').removeClass('pinned');
          }
        }
        $(window).scroll(sticky_relocate_2);
      }
    );
  }
}
