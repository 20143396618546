import retina from 'retinajs';
window.addEventListener('load', retina);

// import './sample.jsx';

import Modal from 'bootstrap';
import 'jquery-match-height';
import Nav from './components/nav';
import Carousel from './components/carousel';
import Timeline from './components/timeline';
import mapboxgl from 'mapbox-gl';
import bsCustomFileInput from 'bs-custom-file-input';

if (!("ontouchstart" in document.documentElement)) {
document.documentElement.className += " no-touch";
}

new Nav();
new Timeline();
new Carousel();

bsCustomFileInput.init();

$(window).on('load', function() {
    $('.m2g-menu-product-container').matchHeight();
});

var myMap = document.getElementById("map");
if(myMap){
    mapboxgl.accessToken = 'pk.eyJ1IjoiYmxhbWVqZW4iLCJhIjoiY2s5b3RkZGtxMDQ2NzNrcG5zanp2emRidyJ9.UiuTlRJiXaiVnCtpC67tCQ';

    var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/blamejen/ck9td6nd30dfl1ipfpl4okeqq',
    center: [174.6595523,-36.8784611],
    zoom: 10
    });
    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());

    var marker = new mapboxgl.Marker()
    .setLngLat([174.7423255, -36.9043603])
    .addTo(map);

    var marker2 = new mapboxgl.Marker()
    .setLngLat([174.6151507, -36.8657553])
    .addTo(map);
}

// Form
(function() {
  'use strict';
  window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
})();




